import React from "react"
import { useSelector } from "react-redux"

import Icon from "components/Icons"
import { Paragraph } from "components/Text"
import { DialogComponent } from "components/ui/dialog"
import { selectClientLimit } from "store/layout/layoutSelectors"
import { ClientDataFile } from "types/Client"
import { getServerErrors, hasConcreteError } from "utils/errors"
import usePrefix from "utils/usePrefix"

interface ImportClientsModalProps {
  isLoading: boolean
  isVisible: boolean
  errors: any
  importedClientsCount: ClientDataFile | null
  onCancel: () => void
  onConfirm: () => void
}

export const ImportClientsModal: React.FC<ImportClientsModalProps> = ({
  isLoading,
  isVisible,
  errors,
  importedClientsCount,
  onCancel,
  onConfirm
}) => {
  const tImport = usePrefix("ImportClient")

  const clientLimit = useSelector(selectClientLimit)

  const clientCount = importedClientsCount
    ? importedClientsCount.greenCount +
      importedClientsCount.yellowCount +
      importedClientsCount.redCount
    : 0

  const hasErrors =
    !!errors ||
    (importedClientsCount && importedClientsCount?.clientCountAboveLimit > 0) ||
    (importedClientsCount &&
      importedClientsCount?.clientDataFileClientLimit > 0)

  const serverErrors = getServerErrors(errors)

  const hasDuplicates = hasConcreteError(errors, "ClientsWithSameEmailAndNip")

  return (
    <DialogComponent
      open={isVisible}
      onOpenChange={!!onCancel && onCancel}
      canClose={false}
      cancelText={tImport("cancel")}
      onCancel={onCancel}
      onConfirm={onConfirm}
      okText={hasErrors ? tImport("tryAgain") : tImport("redirectToClients")}
      isLoading={isLoading}
      className="w-9/12 overflow-auto"
    >
      <div className="flex justify-center">
        <div className="p-14">
          {isLoading && (
            <>
              <div className="flex justify-center">
                <Icon
                  type="loading"
                  className="h-12 w-12 animate-spin text-secondary-foreground"
                />
              </div>
              <div className="flex justify-center">
                <Paragraph>{tImport("importLoaderLabel")}</Paragraph>
              </div>
            </>
          )}

          {hasErrors && (
            <>
              <div className="flex justify-center">
                <h2 style={{ marginBottom: 32 }}>
                  {tImport("importError")}
                  {hasDuplicates &&
                    ` - ${tImport("importErrorDuplicatesTitle")}`}
                </h2>
              </div>
              <div className="justify-center">
                {serverErrors.length > 0
                  ? serverErrors.map((err, index) => (
                      <div className="flex justify-center" key={`err-${index}`}>
                        <Paragraph className="text-center">{err}</Paragraph>
                      </div>
                    ))
                  : !importedClientsCount?.clientCountAboveLimit && (
                      <div className="flex justify-center">
                        <Paragraph className="text-center">
                          {tImport("importError3")}
                        </Paragraph>
                      </div>
                    )}
                <Paragraph className="text-center">
                  {!!importedClientsCount?.clientCountAboveLimit &&
                    tImport("importError2", {
                      count: importedClientsCount.clientCountAboveLimit,
                      limit: clientLimit
                    })}
                </Paragraph>
                <Paragraph className="text-center">
                  {!!importedClientsCount?.clientDataFileClientLimit &&
                    tImport("importError4", {
                      limit: importedClientsCount.clientDataFileClientLimit
                    })}
                </Paragraph>
              </div>
            </>
          )}
          {!isLoading && !hasErrors && importedClientsCount && (
            <>
              <div className="flex justify-center">
                <h2 style={{ marginBottom: 32 }}>
                  {tImport("importedCount", { count: clientCount })}
                </h2>
              </div>

              <div>
                <Paragraph>{tImport("including")}</Paragraph>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ minWidth: 160 }}
                >
                  <div className="mb-5 flex h-16 w-[100px] items-center justify-center rounded-s bg-alternative text-xl font-medium text-white">
                    {importedClientsCount.greenCount}
                  </div>
                  <div className="justify-center">
                    <Paragraph>{tImport("withCorrectData")}</Paragraph>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ minWidth: 160 }}
                >
                  <div className="bg-warning-foreground mb-5 flex h-16 w-[100px] items-center justify-center rounded-s text-xl font-medium text-white">
                    {importedClientsCount.yellowCount}
                  </div>
                  <div>
                    <Paragraph>{tImport("withIncompleteData")}</Paragraph>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ minWidth: 160 }}
                >
                  <div className="mb-5 flex h-16 w-[100px] items-center justify-center rounded-s bg-destructive text-xl font-medium text-white">
                    {importedClientsCount.redCount}
                  </div>
                  <div>
                    <Paragraph>{tImport("withErrorData")}</Paragraph>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </DialogComponent>
  )
}