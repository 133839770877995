import { CheckedState } from "@radix-ui/react-checkbox"
import { useField } from "formik"
import React, { useCallback } from "react"

import { Checkbox } from "components/ui/checkbox"
import { Label } from "components/ui/label"
import { cn } from "lib/utils"

import FormFieldWrapper from "./FormFieldWrapper"

interface FormCheckboxProps {
  name: string
  label?: React.ReactNode
  onCheckedChange?: (checked: CheckedState) => void
  required?: boolean
  disabled?: boolean
  id: string
  className?: string
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  onCheckedChange,
  required = false,
  disabled = false,
  id,
  className
}) => {
  const [field, meta, helpers] = useField(name)
  const { error } = meta

  const handleChange = useCallback(
    (checked: CheckedState) => {
      helpers.setValue(checked)
      if (onCheckedChange) onCheckedChange(checked)
    },
    [helpers, onCheckedChange]
  )

  return (
    <FormFieldWrapper name={name}>
      <div className="flex flex-row gap-2">
        <Checkbox
          id={id}
          {...field}
          checked={field.value}
          onCheckedChange={handleChange}
          disabled={disabled}
          className={cn(
            `${
              error
                ? "border-destructive focus-visible:ring-destructive/50"
                : ""
            }`,
            className
          )}
        />
        <Label htmlFor={id} className="mt-1 cursor-pointer">
          <span>
            {required && <span className="mr-1 text-destructive">*</span>}
            {label}
          </span>
        </Label>
      </div>
    </FormFieldWrapper>
  )
}

export default FormCheckbox