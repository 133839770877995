import React from "react"

import Icon from "components/Icons"
import { ImgMinature } from "components/ImgMiniature"
import { DialogComponent } from "components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Client } from "types/Client"
import { NewsletterConfirmation, ReceiverConfirmation } from "types/Newsletter"
import { formatDate } from "utils/date"
import usePrefix from "utils/usePrefix"

import { ClientList } from "./ClientList"

export type ClientReceiverType = Client &
  Partial<Pick<ReceiverConfirmation, "isNewsConfirmed" | "resendingDateUtc">>

export interface SelectClientProps {
  onSelectClient?: (id: string) => void
  selectedClients?: (string | undefined)[]
  handleSelectAll?: () => void
  isSelectedAll?: boolean
  isResendModal?: boolean
  isClientRemovable?: boolean
  onClientRemove?: (id: string) => void
}

export interface ClientListModalProps {
  isVisible: boolean
  title: React.ReactNode
  subtitle?: string
  clients?: ClientReceiverType[] | null
  header?: React.ReactNode
  footer: React.ReactNode
  onClose: () => void
  confirmation?: NewsletterConfirmation
  isResendModal?: boolean
}

export const ClientListModal: React.FC<
  ClientListModalProps & SelectClientProps
> = ({
  isVisible,
  isClientRemovable = false,
  onClientRemove,
  confirmation,
  title,
  subtitle,
  clients,
  header,
  footer,
  onClose,
  onSelectClient,
  selectedClients,
  handleSelectAll,
  isSelectedAll,
  isResendModal
}) => {
  const t = usePrefix("Confirmation")

  const confirmedClients = clients
    ? clients.filter((client) => client?.isNewsConfirmed)
    : []
  const unconfirmedClients = clients
    ? clients.filter((client) => !client?.isNewsConfirmed)
    : []

  return (
    <DialogComponent
      open={isVisible}
      onOpenChange={onClose}
      hideFooter
      className="w-[95vw] lg:max-w-screen-xl"
    >
      <div style={{ marginBottom: confirmation ? 0 : 16 }}>{title}</div>
      {confirmation && (
        <div className="pl-1">
          <div style={{ marginBottom: 16 }}>
            <h5 style={{ fontWeight: 300 }}>{subtitle}</h5>
          </div>
          <div className="mb-6 flex items-end pt-2">
            <div>
              <ImgMinature imageId={confirmation.news.imageId} size="large" />
            </div>
            <div className="pr-5">
              <h4>{confirmation.news.title}</h4>
              <div>{formatDate(confirmation.sendDateUtc)}</div>
              <div>{confirmation.title}</div>
            </div>
          </div>
        </div>
      )}

      {header}
      {clients &&
        (!confirmation ? (
          <ClientList
            clients={clients}
            isClientRemovable={isClientRemovable}
            onClientRemove={onClientRemove}
          />
        ) : (
          <Tabs defaultValue="unconfirmed">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="unconfirmed">
                <Icon type="angry" className="mr-1 h-4 w-4 stroke-current" />
                {t("unconfirmed")} ({unconfirmedClients.length})
              </TabsTrigger>
              <TabsTrigger value="confirmed">
                <Icon type="smile" className="mr-1 h-4 w-4 stroke-current" />
                {t("confirmed")} ({confirmedClients.length})
              </TabsTrigger>
            </TabsList>
            <TabsContent value="unconfirmed">
              <ClientList
                clients={unconfirmedClients}
                onSelectClient={onSelectClient}
                selectedClients={selectedClients}
                handleSelectAll={handleSelectAll}
                isSelectedAll={isSelectedAll}
                isResendModal={isResendModal}
              />
            </TabsContent>
            <TabsContent value="confirmed">
              <ClientList
                clients={confirmedClients}
                isResendModal={isResendModal}
              />
            </TabsContent>
          </Tabs>
        ))}
      {footer}
    </DialogComponent>
  )
}