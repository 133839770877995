import * as React from "react"

import { cn } from "lib/utils"

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  )
}

const Skeletons = ({ rows, large }: { rows: number; large?: boolean }) => (
  <div className="flex w-full flex-col gap-2">
    {Array.from({ length: rows }, (_, index) => (
      <Skeleton
        key={index}
        className={`${large ? "h-8" : "h-4"} w-full rounded-t-lg`}
      />
    ))}
  </div>
)

export { Skeleton, Skeletons }