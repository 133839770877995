import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import placeholder from "assets/img/placeholder.png"
import { AppState } from "store/AppState"
import { fetchThumbnail, selectThumbnailById } from "store/thumbnail"

interface ImgMinatureProps {
  imageId?: string | null
  size?: "small" | "normal" | "large"
}

export const ImgMinature: React.FC<ImgMinatureProps> = ({
  imageId,
  size = "normal",
  ...props
}) => {
  const dispatch = useDispatch()
  const thumbnail = useSelector((s: AppState) =>
    selectThumbnailById(s, imageId)
  )

  useEffect(() => {
    if (!thumbnail) {
      dispatch(fetchThumbnail(imageId))
    }
  }, [dispatch, imageId, thumbnail])

  const sizeClasses = {
    small: "w-[25px] h-[25px] mr-2",
    normal: "w-[40px] h-[40px] mr-4",
    large: "w-[120px] h-[70px] mr-4"
  }

  return (
    <img
      {...props}
      src={thumbnail || placeholder}
      className={`${sizeClasses[size]} rounded-[5px] object-cover`}
      alt="Thumbnail"
    />
  )
}