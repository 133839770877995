import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import * as React from "react"

import { Button } from "components/Button"
import { cn } from "lib/utils"
import usePrefix from "utils/usePrefix"

interface DialogComponentProps {
  open?: boolean
  trigger?: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  onOpenChange?: (open: boolean) => void
  isLoading?: boolean
  canClose?: boolean
  confirmDisabled?: boolean
  hideFooter?: boolean
  children?: React.ReactNode
  okText?: string
  datagtm?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
  className?: string
}

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    hideCloseButton?: boolean
    canClose?: boolean
  }
>(
  (
    { className, children, hideCloseButton, canClose = true, ...props },
    ref
  ) => (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          "fixed left-[50%] top-[50%] z-50 grid max-h-[95vh] w-full max-w-[95vw] translate-x-[-50%] translate-y-[-50%] content-baseline gap-4 overflow-auto border bg-background px-6 py-8 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-md",
          className
        )}
        style={{ scrollbarWidth: "thin" }}
        {...props}
      >
        {children}
        {canClose && !hideCloseButton && (
          <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity data-[state=open]:bg-accent data-[state=open]:text-muted-foreground hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </DialogPortal>
  )
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

const DialogComponent = ({
  trigger,
  open,
  onOpenChange,
  title,
  description,
  canClose = true,
  onCancel,
  isLoading,
  cancelText,
  datagtm,
  okText,
  onConfirm,
  className,
  confirmDisabled,
  hideFooter,
  children
}: DialogComponentProps) => {
  const tGeneral = usePrefix("General")

  return (
    <Dialog open={open} onOpenChange={canClose ? onOpenChange : undefined}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        hideCloseButton={!canClose}
        canClose={canClose}
        className={className}
      >
        <DialogHeader className={`${!title && "hidden"}`}>
          <DialogTitle>{title && title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className={`${!description && "hidden"}`}>
          {description && description}
        </DialogDescription>
        {children}

        {!hideFooter && (
          <DialogFooter>
            <div
              className={`mt-8 flex w-full ${!!onCancel ? "justify-between" : "justify-center"}`}
            >
              {!!onCancel && (
                <DialogPrimitive.Close asChild>
                  <Button onClick={onCancel} variant="outline">
                    {cancelText ?? tGeneral("cancel")}
                  </Button>
                </DialogPrimitive.Close>
              )}
              <Button
                loading={isLoading}
                onClick={onConfirm}
                variant="default"
                disabled={confirmDisabled}
                {...(datagtm && { datagtm })}
              >
                {okText ?? tGeneral("save")}
              </Button>
            </div>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogComponent
}