import * as React from "react"
import { forwardRef, ReactNode, RefObject } from "react"

import { cn } from "lib/utils"

interface SectionBoxProps {
  children?: ReactNode
  className?: string
  ref?: RefObject<HTMLDivElement>
}

interface DnDWrapperProps {
  children?: ReactNode
  $isDragging: boolean
}

export const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <section className="relative m-auto flex h-full w-full bg-accent">
      <div className="w-full">{children}</div>
    </section>
  )
}

export const PageContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <main className="container mx-auto min-h-[calc(100vh-224px)] w-full px-4 pt-6">
      {children}
    </main>
  )
}

export const MiddleContainer = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        "container m-auto flex h-full flex-col px-6 py-0",
        className
      )}
    >
      {children}
    </div>
  )
}

export const SectionBox = forwardRef<HTMLDivElement, SectionBoxProps>(
  ({ children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cn("border border-border bg-white p-8", className)}
      >
        {children}
      </div>
    )
  }
)

export const DnDWrapper = ({ $isDragging, children }: DnDWrapperProps) => {
  return (
    <div className={`cursor-grab ${$isDragging ? "opacity-0" : "opacity-100"}`}>
      {children}
    </div>
  )
}