import React from "react"

import { Button } from "components/Button"
import Icon from "components/Icons"
import { Paragraph } from "components/Text"
import { DialogComponent } from "components/ui/dialog"

interface WarningModalProps {
  title: string
  subtitle?: string
  mainBtnTitle: string
  altBtnTitle?: string
  mainBtnAction: () => void
  altBtnAction?: () => void
  onCancel: () => void
  description?: string
}

export const WarningModal: React.FC<WarningModalProps> = ({
  title,
  subtitle,
  mainBtnTitle,
  altBtnTitle,
  mainBtnAction,
  altBtnAction,
  onCancel,
  description
}) => {
  return (
    <DialogComponent
      open
      onOpenChange={onCancel}
      canClose={false}
      hideFooter
      className="flex w-9/12 flex-col items-center overflow-auto py-24"
    >
      <Icon
        type="noFile"
        width="130px"
        height="130px"
        className="stroke-warning-foreground"
        style={{
          boxSizing: "content-box",
          padding: "50px"
        }}
      />
      <h2 className="stroke-warning-foreground">{title}</h2>
      {subtitle && <h2>{subtitle}</h2>}
      {description && (
        <Paragraph className="py-5 text-center text-xl text-primary">
          {description}
        </Paragraph>
      )}

      <div className="flex gap-4">
        <div>
          <Button onClick={mainBtnAction} variant="default">
            {mainBtnTitle}
          </Button>
        </div>

        {altBtnTitle && altBtnAction && (
          <div>
            <Button onClick={altBtnAction}>{altBtnTitle}</Button>
          </div>
        )}
      </div>
    </DialogComponent>
  )
}