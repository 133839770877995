import React, { useState, useEffect } from "react"

import { Button } from "components/Button"
import * as LocalStorage from "store/storage/localStorage"
import usePrefix from "utils/usePrefix"

export const CookiePolicy: React.FC = () => {
  const t = usePrefix("General")
  const [isDisclaimerVisible, setDisclaimerVisibility] = useState(false)

  useEffect(() => {
    const hideDisclaimer = LocalStorage.loadLocalState(
      LocalStorage.HIDE_DISCLAIMER
    )
    setDisclaimerVisibility(!hideDisclaimer)
  }, [])

  const handleClose = () => {
    setDisclaimerVisibility(false)
    LocalStorage.saveLocalState(LocalStorage.HIDE_DISCLAIMER, true)
  }

  if (!isDisclaimerVisible) {
    return null
  }

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full bg-card-foreground p-6 font-light text-white">
      <div className="px-4">
        <span>{t("cookies-1")}</span>
        <a
          href="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
          target="blank"
          className="text-primary hover:underline"
        >
          {t("cookies-link-policy")}
        </a>
        <span>{t("cookies-2")}</span>
        <a
          href="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
          target="blank"
          className="text-primary hover:underline"
        >
          {t("cookies-link-help")}
        </a>
        <Button
          size="lg"
          variant="secondary"
          style={{ marginTop: 24, display: "block" }}
          onClick={handleClose}
        >
          {t("cookies-btn-close")}
        </Button>
      </div>
      <Button
        iconType="close"
        variant="ghost"
        size="sm"
        onClick={handleClose}
        className="absolute right-5 top-5 hover:bg-transparent hover:text-muted-foreground"
      />
    </div>
  )
}