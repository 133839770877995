import { Api } from "api"
import { RoleEnum } from "enums"
import * as LocalStorage from "store/storage/localStorage"
import { Role } from "types/Role"
import { AvailableFeaturesType, Session, Tokens } from "types/Session"
import { getLoginUrl, getLogoutUrl } from "utils/config"
import { parseQuery } from "utils/query"

import { refreshToken as fetchRefreshToken } from "./users/usersApi"

const getTimeEndInSec = (timeEndInSec?: number) =>
  Date.now() + Number(timeEndInSec ?? 600) * 1000

class Manager {
  private refreshPromise: Promise<Tokens> | null = null
  private sessionEndTimestamp: number = getTimeEndInSec(
    LocalStorage.loadLocalState(LocalStorage.SESSION)?.tokens
      ?.refreshTokenValidityInSecs ?? Date.now()
  )

  constructor() {}

  public getSession(): Session | null {
    return LocalStorage.loadLocalState(LocalStorage.SESSION)
  }

  public setSession(session: Session): void {
    LocalStorage.saveLocalState(LocalStorage.SESSION, session)
    this.sessionEndTimestamp = getTimeEndInSec(
      session.tokens.refreshTokenValidityInSecs
    )
  }

  public getSessionTokens() {
    const session = this.getSession()

    return session?.tokens
  }

  public getSessionEndTimestamp() {
    return this.sessionEndTimestamp
  }

  public setSessionEndTimestamp(sessionEndTimestamp: number) {
    this.sessionEndTimestamp = getTimeEndInSec(sessionEndTimestamp)
  }

  public getUserRole() {
    const session = this.getSession()
    return session?.userRole
  }

  public getAORole() {
    const userRole = this.getUserRole()
    return userRole === RoleEnum.AO_Publisher || userRole === RoleEnum.AO_User
  }

  public getIsAOPublisher() {
    const session = this.getSession()
    return session?.userRole === RoleEnum.AO_Publisher
  }

  public getIsAOUser() {
    const session = this.getSession()
    return session?.userRole === RoleEnum.AO_User
  }

  public getUserFirstName() {
    const session = this.getSession()
    return session?.firstName ?? ""
  }

  public getUserLastName() {
    const session = this.getSession()
    return session?.lastName ?? ""
  }

  public getUserEmail() {
    const session = this.getSession()
    return session?.email
  }

  public getUserFullName() {
    return `${this.getUserFirstName()} ${this.getUserLastName()}`
  }

  public getRealm() {
    const session = this.getSession()
    return session?.realm ?? "Accountancy"
  }

  public getAvailableFeatures() {
    const session = this.getSession()
    return session?.availableFeatures ?? []
  }

  public hasRequiredFeature(feature: AvailableFeaturesType) {
    const features = this.getAvailableFeatures()
    return features.includes(feature)
  }

  private async getBorgLogOnUrl() {
    try {
      const res = await Api.getBorgLogOnUrl()

      LocalStorage.saveLocalState(LocalStorage.BORG_LOGIN_URL, res.result)
    } catch (err) {
      console.error(err)
    }
  }

  public async authorize() {
    const query = parseQuery(window.location.search)
    const session = this.getSession()

    await this.getBorgLogOnUrl()
    if (query.borg_sessionId) {
      try {
        const session = await Api.authenticate(query.borg_sessionId)
        this.setSession(session.result)
        return true
      } catch (err) {
        console.error(err)
        this.redirectToLogin()
      }
    } else if (!session) {
      this.redirectToLogin()
    }

    return !!this.getSession()
  }

  public redirectToLogin() {
    setTimeout(() => {
      window.location.assign(
        `${getLoginUrl()}&originalReferrer=${encodeURIComponent(
          document.referrer
        )}`
      )
    }, 1500)
  }

  private redirectToLogout() {
    const logoutUrl = getLogoutUrl()
    LocalStorage.removeLocalState(LocalStorage.SESSION)
    LocalStorage.removeLocalState(LocalStorage.BORG_ID)
    LocalStorage.removeLocalState(LocalStorage.IGNORE_EXCEEDED_LIMIT)

    if (logoutUrl) {
      window.location.assign(logoutUrl)
    }
  }

  public logout() {
    this.redirectToLogout()
  }

  public async refreshToken(lastActInSecs: number) {
    const session = this.getSession()
    if (!session) {
      this.logout()
      return
    }

    const sessionRefreshToken = session?.tokens.refreshToken

    if (this.refreshPromise) {
      return this.refreshPromise
    }

    this.refreshPromise = new Promise(async (resolve, reject) => {
      try {
        if (sessionRefreshToken) {
          const res = await fetchRefreshToken({
            refreshToken: sessionRefreshToken,
            lastActInSecs: lastActInSecs
          })

          if (res.result) {
            this.setSession({ ...session, tokens: res.result })
            resolve(res.result)
          } else {
            reject(res)
          }
        } else {
          reject(new Error("Refresh token is undefined"))
        }
      } catch (err) {
        this.logout()
        reject(err)
      }
      this.refreshPromise = null
    })

    return this.refreshPromise
  }

  public setNextRole(role: Role) {
    const session = this.getSession()
    if (session) {
      this.setSession({ ...session, userRole: role })
    }
  }
}

const SessionManager = new Manager()

export default SessionManager