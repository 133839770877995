import React from "react"
import { ReactourStep } from "reactour"

import { Paragraph } from "components/Text"
import { TransFunc } from "utils/usePrefix"

import { SimpleStep } from "../components/SimpleStep"

export const mainPageSteps = (t: TransFunc): ReactourStep[] => [
  {
    selector: "[data-tour='main-page-step-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_1_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_1_line_2")}</Paragraph>
              <Paragraph>{t("main_page_step_1_line_3")}</Paragraph>
            </>
          }
          onNextStep={() => goTo(1)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-newslist']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_2_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_2_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(0)}
          onNextStep={() => goTo(2)}
        />
      )
    }
  },
  {
    selector: "[datatour='main-page-step-news-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_3_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_3_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(1)}
          onNextStep={() => goTo(3)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-lastsent']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_4_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_4_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(2)}
          onNextStep={() => goTo(4)}
        />
      )
    }
  },
  {
    selector: "[datatour='main-page-step-sent-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_5_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_5_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(3)}
          onNextStep={() => goTo(5)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-confirmations']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_6_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_6_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(4)}
          onNextStep={() => goTo(6)}
        />
      )
    }
  },
  {
    selector: "[datatour='main-page-step-confirmations-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_7_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_7_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(5)}
          onNextStep={() => goTo(7)}
        />
      )
    }
  },
  {
    selector: "[datatour='main-page-step-clients-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_8_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_8_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(6)}
          onNextStep={() => goTo(8)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-bulletin-cta']",
    stepInteraction: false,
    content: ({ goTo, close }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("main_page_step_9_line_1")}</Paragraph>
              <Paragraph>{t("main_page_step_9_line_2")}</Paragraph>
            </>
          }
          nextStepLabel={t("end")}
          onPrevStep={() => goTo(7)}
          onNextStep={close}
        />
      )
    }
  }
]