import { ColumnDef, flexRender } from "@tanstack/react-table"
import React from "react"

import Icon from "components/Icons"
import { Skeletons } from "components/ui/skeleton"
import {
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from "components/ui/table"
import usePrefix from "utils/usePrefix"

import { useTable } from "./useTable"

type GenericTableProps<T> = {
  data: T[]
  columns: ColumnDef<T>[]
  isLoading?: boolean
  hideHeader?: boolean
  className?: string
}

export function GenericTable<T>({
  columns,
  data,
  hideHeader = false,
  className,
  isLoading
}: Readonly<GenericTableProps<T>>) {
  const tGeneral = usePrefix("General")

  const { table } = useTable({
    data,
    columns
  })

  return (
    <div className="w-full">
      <div className="overflow-x-auto border">
        <Table className={`min-w-[min-content] ${className}`}>
          {!hideHeader && (
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
          )}
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <div className="flex flex-col items-center justify-center gap-4 text-muted-foreground/70">
                    <Skeletons rows={6} large />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <div className="flex h-40 flex-col items-center justify-center gap-4 text-muted-foreground/70">
                    <Icon
                      type="packageOpen"
                      className="h-10 w-10 stroke-muted-foreground/70 stroke-[0.8]"
                    />
                    <div>{tGeneral("no_data")}</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}