import React, { useEffect, useState } from "react"

import { SectionBox } from "components/AppLayout/PageContainer"
import { Skeletons } from "components/ui/skeleton"
import { useClientRect } from "utils/hooks"

interface LoadingOpenWrapperProps {
  isExpanded: boolean
  isLoading: boolean
  children: React.ReactNode
}

export const LoadingOpenWrapper: React.FC<LoadingOpenWrapperProps> = ({
  isExpanded,
  isLoading,
  children
}) => {
  const [rect, ref] = useClientRect()

  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (isExpanded) {
      setHeight(isLoading ? 100 : rect?.height || 0)
    } else {
      setHeight(0)
    }
  }, [isExpanded, isLoading, rect])

  return (
    <div
      className={`overflow-hidden transition-all duration-100 ${!isExpanded && "hidden"} h-[${height}px]`}
    >
      {isLoading ? (
        <SectionBox>
          <Skeletons rows={2} />
        </SectionBox>
      ) : (
        <div ref={ref}>{children}</div>
      )}
    </div>
  )
}