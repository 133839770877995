import * as React from "react"
import { SingleValue } from "react-select"

import FormRadioGroup from "components/Form/FormRadioGroup"
import Icon from "components/Icons"
import SingleSelect, {
  SingleSelectOption
} from "components/Inputs/Select/SingleSelect"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { TooltipComponent } from "components/ui/tooltip"
import {
  AutoNewsletterDeliveryValues,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type MonthlyConfigProps = {
  values: AutoNewsletterDeliveryValues
  onChange: FieldValueUpdate
}

const WeekAndDaySelects: React.FC<{
  weekNumber: number
  dayOfWeek: string
  onChange: FieldValueUpdate
}> = ({ weekNumber, dayOfWeek, onChange }) => {
  const t = usePrefix("AutoDelivery")

  return (
    <div className="flex items-center">
      <div className="mx-1.5 inline-block w-24">
        <SingleSelect
          isSearchable={false}
          value={
            options.weeksNumber
              .map((weekNumber) => ({
                value: weekNumber,
                label: weekNumber
              }))
              .find((option) => option.value === weekNumber) || undefined
          }
          onChange={(value) =>
            onChange(
              "weekNumber",
              (value as SingleValue<SingleSelectOption>)?.value
            )
          }
          options={options.weeksNumber.map((weekNumber) => ({
            value: weekNumber,
            label: weekNumber
          }))}
        />
      </div>
      <div className="mx-1.5 inline-block w-36">
        <SingleSelect
          isSearchable={false}
          value={{ value: dayOfWeek, label: t(`${dayOfWeek}`) }}
          onChange={(value) =>
            onChange(
              "dayOfWeek",
              (value as SingleValue<SingleSelectOption>)?.value
            )
          }
          options={options.daysOfWeek.map((day) => ({
            value: day,
            label: t(`${day}`)
          }))}
        />
      </div>
      <div className="inline">{t("months")}</div>
    </div>
  )
}

const DayOfMonthSelect: React.FC<{
  dayOfMonth: number
  onChange: FieldValueUpdate
}> = ({ dayOfMonth, onChange }) => {
  const t = usePrefix("AutoDelivery")

  return (
    <div className="flex items-center">
      <div className="mx-1.5 inline-block w-20">
        <SingleSelect
          isSearchable={false}
          value={
            options.dayOfMonth
              .map((day) => ({ value: day, label: day }))
              .find((option) => option.value === dayOfMonth) || undefined
          }
          onChange={(value) =>
            onChange(
              "dayOfMonth",
              (value as SingleValue<SingleSelectOption>)?.value
            )
          }
          options={options.dayOfMonth.map((dayOfMonth) => ({
            value: dayOfMonth,
            label: dayOfMonth
          }))}
        />
      </div>
      <div className="inline">{t("dayOfMonth")}</div>
      <TooltipComponent
        tooltipContent={t("tooltipNotFoundDay")}
        triggerContent={
          <div className="ml-1 inline">
            <Icon type="infoRound" className="h-4 w-4" />
          </div>
        }
      />
    </div>
  )
}

export const MonthlyConfig: React.FC<MonthlyConfigProps> = ({
  values,
  onChange
}) => {
  const { weekNumber, dayOfMonth, dayOfWeek } = values

  const radioOptions = [
    {
      value: "false",
      label: ""
    },
    {
      value: "true",
      label: ""
    }
  ]

  return (
    <div className="flex flex-row items-center space-x-2">
      <FormRadioGroup
        name="isDayOfMonth"
        options={radioOptions}
        onChange={(value) => onChange("isDayOfMonth", value === "true")}
        className="w-min flex-col gap-8"
      />
      <div className="flex flex-col items-start gap-2">
        <WeekAndDaySelects
          weekNumber={weekNumber}
          dayOfWeek={dayOfWeek}
          onChange={onChange}
        />
        <DayOfMonthSelect dayOfMonth={dayOfMonth} onChange={onChange} />
      </div>
    </div>
  )
}