import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { MiddleContainer } from "components/AppLayout/PageContainer"
import { Button } from "components/Button"
import { Separator } from "components/ui/separator"
import { selectBottomBarStatus } from "store/layout"
import usePrefix from "utils/usePrefix"

import logo from "../../assets/img/wk-brand.svg"

export const PageFooter = () => {
  const t = usePrefix("General")
  const withBottomBar = useSelector(selectBottomBarStatus)

  return (
    <footer className={`${!withBottomBar ? "mt-10" : "mt-24"}`}>
      {!withBottomBar && (
        <>
          <div className="w-full py-6 text-xs text-gray-500">
            <MiddleContainer>
              <Separator className="mx-0 my-6" />
              <div className="mb-4 self-center">{t("copyright")}</div>
              <div className="self-center">
                <img src={logo} className="h-9" alt="Brand Logo" />
              </div>
            </MiddleContainer>
          </div>
          <div className={`bg-white py-5 ${withBottomBar ? "mb-24" : "mb-0"}`}>
            <MiddleContainer>
              <div className="flex justify-end">
                <Link
                  to="https://www.wolterskluwer.com/pl-pl/terms-of-use"
                  target="_blank"
                >
                  <Button variant="link" className="hover:underline">
                    {t("terms-of-use-btn")}
                  </Button>
                </Link>
                <Link
                  to="https://www.wolterskluwer.com/pl-pl/privacy-cookies"
                  target="_blank"
                >
                  <Button variant="link" className="hover:underline">
                    {t("privacy-policy-btn")}
                  </Button>
                </Link>
              </div>
            </MiddleContainer>
          </div>
        </>
      )}
      <div className="fixed bottom-0 z-[1] w-full" id="bottomBarPortal" />
    </footer>
  )
}