import React, { useCallback, useEffect, useState } from "react"
import Select, {
  components as reactSelectComponents,
  Props as SelectProps,
  MultiValue,
  SingleValue,
  ActionMeta,
  OptionProps,
  NoticeProps,
  MenuListProps
} from "react-select"
import CreatableSelect from "react-select/creatable"
import { FixedSizeList as List } from "react-window"

import Icon from "components/Icons"
import { SingleSelectOption } from "components/Inputs/Select/SingleSelect"
import { TooltipComponent } from "components/ui/tooltip"
import { cn } from "lib/utils"
import usePrefix from "utils/usePrefix"

export interface MultipleSelectOption extends SingleSelectOption {
  level?: number // Required for PKD MultipleSelect
}

type MultipleSelectProps = Omit<
  SelectProps<MultipleSelectOption>,
  "onChange" | "onCreateOption"
> & {
  onSelectChange?: (
    newValue: MultiValue<MultipleSelectOption>,
    actionMeta: ActionMeta<MultipleSelectOption>
  ) => void
  onCreateTag?: (newValue: string) => void
  allowCreate?: boolean
  additionalPadding?: boolean
  virtualList?: boolean
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  options: defaultOptions,
  value: defaultValues,
  name,
  additionalPadding,
  hideSelectedOptions = false,
  isClearable = false,
  onSelectChange,
  closeMenuOnSelect = false,
  onCreateTag,
  allowCreate = false,
  placeholder = "",
  components,
  isDisabled,
  virtualList = false,
  ...props
}) => {
  const t = usePrefix("General")
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState(defaultOptions)
  const [values, setValues] = useState<MultipleSelectOption[]>(
    Array.isArray(defaultValues) ? defaultValues : []
  )

  useEffect(() => {
    setValues(Array.isArray(defaultValues) ? defaultValues : [])
  }, [defaultValues])

  useEffect(() => {
    setOptions(defaultOptions)
  }, [defaultOptions])

  const handleCreate = useCallback(
    async (inputValue: string) => {
      if (allowCreate && onCreateTag) {
        setIsLoading(true)
        onCreateTag(inputValue)

        const flattenedOptions = options?.flatMap((option) =>
          "options" in option ? option.options : option
        )

        const newOption = flattenedOptions?.find(
          (option) => option.label === inputValue
        )

        if (newOption) {
          setValues((prevValues) => [...prevValues, newOption])
        }

        setIsLoading(false)
      }
    },
    [options, onCreateTag, allowCreate]
  )

  const handleChange = useCallback(
    (
      newValue:
        | MultiValue<MultipleSelectOption>
        | SingleValue<MultipleSelectOption>,
      actionMeta: ActionMeta<MultipleSelectOption>
    ) => {
      const updatedValues = Array.isArray(newValue)
        ? newValue
        : newValue
          ? [newValue]
          : []

      setValues(updatedValues as MultipleSelectOption[])
      if (onSelectChange) {
        onSelectChange(
          updatedValues as MultiValue<MultipleSelectOption>,
          actionMeta
        )
      }
    },
    [onSelectChange]
  )

  const Option = (props: OptionProps<MultipleSelectOption>) => {
    const isSelected =
      Array.isArray(values) && values.some((v) => v.value === props.data.value)

    return (
      <>
        <reactSelectComponents.Option {...props}>
          <TooltipComponent
            triggerContent={
              <span className="inline-flex w-full items-center">
                {isSelected && (
                  <Icon
                    type="check"
                    className="mr-1.5 h-4 w-4 flex-shrink-0 stroke-primary"
                  />
                )}
                <span
                  className="truncate pr-2"
                  style={{
                    paddingLeft: additionalPadding
                      ? `${(props.data.level ?? 1) * 10}px`
                      : undefined,
                    fontWeight: isSelected ? 500 : 400
                  }}
                >
                  {props.data.label}
                </span>
              </span>
            }
            tooltipContent={props.data.label}
            asPortal
          />
        </reactSelectComponents.Option>
      </>
    )
  }

  const NoOptionsMessage = (props: NoticeProps<MultipleSelectOption>) => {
    return (
      <reactSelectComponents.NoOptionsMessage {...props}>
        <span className="text-sm">{t("no_data")}</span>
      </reactSelectComponents.NoOptionsMessage>
    )
  }

  const MenuList = (props: MenuListProps<MultipleSelectOption, true>) => {
    if (virtualList) {
      const { children, maxHeight } = props

      const childrenArray = React.Children.toArray(children)

      const itemHeight = 36

      const calculatedHeight = Math.min(
        childrenArray.length * itemHeight,
        maxHeight
      )

      return (
        <List
          height={calculatedHeight}
          itemCount={childrenArray.length}
          itemSize={itemHeight}
          width="100%"
          style={{ scrollbarWidth: "thin" }}
        >
          {({ index, style }) => (
            <div style={style}>{childrenArray[index]}</div>
          )}
        </List>
      )
    }
    return (
      <reactSelectComponents.MenuList {...props}>
        {props.children}
      </reactSelectComponents.MenuList>
    )
  }

  const selectProps = {
    ...props,
    name,
    hideSelectedOptions,
    isClearable,
    isMulti: true,
    closeMenuOnSelect,
    placeholder,
    isDisabled: isLoading || isDisabled,
    isLoading: isLoading,
    options,
    onChange: handleChange,
    value: values,
    isOptionDisabled: (option: MultipleSelectOption) => !!option.optionDisabled,
    components: {
      ...components,
      MenuList,
      Option,
      NoOptionsMessage
    },
    className: cn("my-react-select-container"),
    classNamePrefix: "my-react-select"
  }

  return allowCreate ? (
    <CreatableSelect
      {...selectProps}
      onCreateOption={handleCreate}
      formatCreateLabel={(inputValue) => (
        <div className="flex w-full items-center justify-between gap-2">
          <span className="text-primary">Dodaj nową etykietę</span>
          <span className="truncate">{` "${inputValue}"`}</span>
        </div>
      )}
    />
  ) : (
    <Select {...selectProps} />
  )
}

export default React.memo(MultipleSelect)