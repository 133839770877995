import React from "react"

import { Button } from "components/Button"
import Icon from "components/Icons"
import { DialogComponent } from "components/ui/dialog"

interface SuccessModalProps {
  title: string
  subtitle?: string
  mainBtnTitle: string
  altBtnTitle?: string
  mainBtnAction: () => void
  altBtnAction?: () => void
  onCancel?: () => void
  dataGtm?: string
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  title,
  subtitle,
  mainBtnTitle,
  altBtnTitle,
  mainBtnAction,
  altBtnAction,
  onCancel,
  dataGtm
}) => {
  return (
    <DialogComponent
      open
      hideFooter
      onOpenChange={onCancel}
      title={title}
      canClose={false}
      className="flex w-9/12 flex-col items-center py-24"
    >
      {subtitle && <h2>{subtitle}</h2>}
      <Icon
        type="checkCircle"
        width="130px"
        height="130px"
        className="stroke-alternative"
        style={{
          boxSizing: "content-box",
          padding: "50px"
        }}
      />
      <div className="gap-4">
        <div className="flex justify-center">
          <Button onClick={mainBtnAction} variant="default" data-gtm={dataGtm}>
            {mainBtnTitle}
          </Button>
        </div>
        {altBtnTitle && altBtnAction && (
          <div className="flex justify-center">
            <Button onClick={altBtnAction}>{altBtnTitle}</Button>
          </div>
        )}
      </div>
    </DialogComponent>
  )
}