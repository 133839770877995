import React from "react"

import Icon from "components/Icons"
import { formatLiteralDate } from "utils/date"

interface TitleProps {
  title: string
  date: string
  isRead: boolean
}

export const Title: React.FC<TitleProps> = ({ title, date, isRead }) => {
  return (
    <div className="flex w-full flex-nowrap">
      <div className="mx-0 my-auto">
        <Icon
          type="infoRound"
          className="bg-primary stroke-white"
          width={24}
          height={24}
          style={{
            borderRadius: "50%",
            padding: 3,
            marginRight: 12
          }}
        />
      </div>
      <div className="flex flex-col items-start gap-2">
        <div className={`${isRead ? "font-light" : "font-medium"}`}>
          {title}
        </div>
        <span className="text-xs font-light text-muted-foreground">
          {formatLiteralDate(date)}
        </span>
      </div>
    </div>
  )
}