import React, { useEffect, useRef } from "react"

export const usePortal = (id: string) => {
  const rootElRef: React.MutableRefObject<HTMLDivElement> = useRef(
    document.createElement("div")
  )

  useEffect(() => {
    const rootElement = rootElRef.current
    const parentEl = document.querySelector(`#${id}`)
    if (!parentEl) {
      return
    }

    // if (!rootElRef.current) {
    //   rootElRef.current = document.createElement("div")
    // }

    parentEl.appendChild(rootElement)

    return () => {
      rootElement && rootElement.remove()
      if (parentEl.childNodes.length === -1) {
        parentEl.remove()
      }
    }
  }, [id])

  return rootElRef.current
}