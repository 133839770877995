import React from "react"

import { Tag } from "components/Tag"
import { TagStructure } from "types/Template"

interface SingleGroupProps {
  formType: "news" | "client"
  selected: number[]
  selectedAlternative: number[]
  group: TagStructure[]
  isTagValidationOff?: boolean
  handleChangeTag: (
    tag: TagStructure,
    group: TagStructure[],
    isChecked: boolean,
    isIndeterminate?: boolean
  ) => void
}

export const SingleGroup: React.FC<SingleGroupProps> = ({
  formType,
  selected,
  selectedAlternative,
  group,
  isTagValidationOff,
  handleChangeTag
}) => {
  const isGroupRequiredValid =
    formType !== "client" ||
    group.length === 1 ||
    group.some((tag) => selected.includes(tag.code))

  return (
    <div className="inline">
      {group.map((tag, index) => {
        const isFirstChild = index === 0
        const isLastChild = index === group.length - 1
        const isOnlyChild = group.length === 1

        return (
          <Tag
            key={tag.code}
            checked={selected?.includes(tag.code)}
            isIndeterminate={selectedAlternative?.includes(tag.code)}
            onChange={(isChecked, isIndeterminate) =>
              handleChangeTag(tag, group, isChecked, isIndeterminate)
            }
            formType={formType}
            tip={tag?.tip}
            className={`ml-[-1px] rounded-none ${!isGroupRequiredValid && !isTagValidationOff && "border-destructive"} ${isFirstChild && "rounded-l-md"} ${isLastChild && "rounded-r-md"} ${isOnlyChild && "rounded-md"}`}
          >
            {tag.name}
          </Tag>
        )
      })}
    </div>
  )
}