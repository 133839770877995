import React, { ReactNode } from "react"

import { cn } from "lib/utils"

interface ParagraphProps {
  onClick?: () => void
  className?: string
  children?: React.ReactNode
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <p
      {...props}
      className={cn(
        `whitespace-pre-line break-words text-base text-secondary-foreground ${props.onClick ? "cursor-pointer" : "cursor-default"}`,
        className
      )}
    >
      {children}
    </p>
  )
}

export const Header: React.FC<{
  children?: ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "mb-4 text-xs font-medium tracking-wide text-secondary-foreground",
        className
      )}
    >
      {children}
    </div>
  )
}

export const SectionHeader = ({
  children,
  className
}: {
  children?: ReactNode
  className?: string
}) => {
  return (
    <h4 className={cn("flex items-center gap-2 text-foreground", className)}>
      {children}
    </h4>
  )
}

export const ListMetaTitle = ({
  children,
  className
}: {
  children?: ReactNode
  className?: string
}) => {
  return (
    <h2 className={cn("mb-2 text-base text-foreground", className)}>
      {children}
    </h2>
  )
}

export type TextProps = {
  $type?: FontType
  $variant?: VariantType
  $nowrap?: boolean
  className?: string
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLSpanElement>

type FontType = "small" | "normal" | "large" | "xLarge" | "xxLarge" | "xxxLarge"
type VariantType = "light" | "normal" | "bold" | "bolder"

const fontType: Record<FontType, string> = {
  small: "text-xs",
  normal: "text-sm",
  large: "text-base",
  xLarge: "text-lg",
  xxLarge: "text-xl",
  xxxLarge: "text-2xl"
}

const fontVariant: Record<VariantType, string> = {
  light: "font-light",
  normal: "font-normal",
  bold: "font-bold",
  bolder: "font-extrabold"
}

export const Text: React.FC<TextProps> = ({
  children,
  $type = "normal",
  $variant = "normal",
  $nowrap = false,
  className,
  ...props
}) => {
  const classNames = `${fontType[$type]} ${fontVariant[$variant]} ${$nowrap ? "whitespace-nowrap" : ""} text-secondary-foreground`
  return (
    <span className={cn(classNames, className)} {...props}>
      {children}
    </span>
  )
}