import { useField } from "formik"
import React, { useCallback } from "react"

import { Input } from "components/ui/input"
import { cn } from "lib/utils"

import FormFieldWrapper from "./FormFieldWrapper"

type FormTextInputProps = {
  name: string
  label?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  disabled?: boolean
  placeholder?: string
  type?: "text" | "password"
  charLimit?: string
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  className?: string
}

const FormTextInput = ({
  name,
  label,
  onChange,
  required = false,
  disabled = false,
  placeholder,
  type = "text",
  charLimit,
  addonBefore,
  addonAfter,
  className
}: FormTextInputProps) => {
  const [field, meta, helpers] = useField(name)
  const { error, touched } = meta

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value)
      if (onChange) onChange(e)
    },
    [helpers, onChange]
  )

  return (
    <FormFieldWrapper
      name={name}
      label={label}
      required={required}
      charLimit={charLimit}
    >
      <div className="relative flex items-stretch">
        {addonBefore && (
          <div className="whitespace-no-wrap flex items-center text-nowrap rounded-l-none border border-r-0 border-input px-3 text-sm leading-normal">
            {addonBefore}
          </div>
        )}
        <Input
          id={name}
          {...field}
          type={type}
          placeholder={placeholder || ""}
          onChange={handleChange}
          disabled={disabled}
          className={cn(
            `${addonBefore ? "rounded-l-none" : ""} ${addonAfter ? "rounded-r-none" : ""} ${
              touched && error
                ? "border-destructive focus-visible:ring-destructive/50"
                : ""
            }`,
            className
          )}
        />
        {addonAfter && (
          <div className="whitespace-no-wrap flex items-center text-nowrap rounded-r-lg border border-l-0 border-input px-3 text-sm leading-normal">
            {addonAfter}
          </div>
        )}
      </div>
    </FormFieldWrapper>
  )
}

export default FormTextInput