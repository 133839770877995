import * as React from "react"
import { SingleValue } from "react-select"

import SingleSelect, {
  SingleSelectOption
} from "components/Inputs/Select/SingleSelect"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import {
  AutoNewsletterDeliveryType,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type WeeklyProps = {
  dayOfWeek: AutoNewsletterDeliveryType["dayOfWeek"]
  onChange: FieldValueUpdate
}

export const WeeklyConfig: React.FC<WeeklyProps> = ({
  dayOfWeek,
  onChange
}) => {
  const t = usePrefix("AutoDelivery")

  return (
    <div className="flex p-4">
      <div
        className="text-secondary-foreground"
        style={{ lineHeight: "24px", fontSize: "14px" }}
      >
        <div className="inline">{t("inEvery")}</div>
        <div
          style={{
            width: "140px",
            display: "inline-block",
            margin: "0 6px"
          }}
        >
          <SingleSelect
            isSearchable={false}
            onChange={(value) =>
              onChange(
                "dayOfWeek",
                (value as SingleValue<SingleSelectOption>)?.value
              )
            }
            value={
              options.daysOfWeek
                .map((day) => ({
                  value: day,
                  label: t(`${day}`)
                }))
                .find((option) => option.value === dayOfWeek) || undefined
            }
            options={options.daysOfWeek.map((day) => ({
              value: day,
              label: t(`${day}`)
            }))}
          />
        </div>
      </div>
    </div>
  )
}