import React from "react"

import Icon from "components/Icons"
import { Avatar, AvatarFallback } from "components/ui/avatar"
import { TooltipComponent } from "components/ui/tooltip"
import { getInitials } from "utils/strings"

interface AuthorAvatarProps {
  fullName?: string
  tooltip?: string
  large?: boolean
  withBurger?: boolean
  onClick?: () => void
}

export const AuthorAvatar: React.FC<AuthorAvatarProps> = ({
  fullName,
  tooltip,
  large,
  withBurger,
  onClick
}) => {
  if (!fullName?.trim()) return null

  return (
    <TooltipComponent
      triggerContent={
        <div className="cursor-pointer hover:opacity-80" onClick={onClick}>
          <Avatar className={`${large ? "h-16 w-16" : "h-8 w-8"}`}>
            <AvatarFallback
              className={`${large ? "bg-primary text-xl text-white" : "text-sm text-primary"}`}
            >
              {getInitials(fullName as string)}
            </AvatarFallback>
          </Avatar>
          {withBurger && (
            <div className="absolute bottom-[9px] right-[-5px] flex h-4 w-4 items-center justify-center rounded-full bg-primary p-[3px]">
              <Icon type="menu" width="15px" height="15px" />
            </div>
          )}
        </div>
      }
      tooltipContent={tooltip || fullName}
    />
  )
}