import React from "react"
import { useDispatch, useSelector } from "react-redux"

import Icon from "components/Icons"
import NotificationContent from "components/UserNotifications/NotificationContent"
import NotificationHeader from "components/UserNotifications/NotificationHeader"
import { Badge } from "components/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { fetchNotifications, selectUnreadCount } from "store/notifications"

import useRefreshNotifications from "./useRefreshNotifications"

const UserNotifications = () => {
  const dispatch = useDispatch()
  const unreadedCount = useSelector(selectUnreadCount)

  useRefreshNotifications()

  const onOpen = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(fetchNotifications())
    }
  }

  return (
    <div data-gtm="gtm-notifications-list" className="mr-6 flex">
      <Popover onOpenChange={onOpen}>
        <PopoverTrigger asChild className="cursor-pointer">
          <div className="relative">
            <Icon
              type="notificationOn"
              className="stroke-white"
              width="16px"
              height="16px"
              style={{ marginTop: 7 }}
            />
            <Badge className="absolute bottom-[8px] left-[8px] bg-destructive p-0 hover:bg-destructive/90">
              <span className="px-1 text-[10px]">{unreadedCount}</span>
            </Badge>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-fit gap-2 px-0">
          <NotificationHeader />
          <NotificationContent />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default UserNotifications