import { SortDirection } from "@tanstack/react-table"
import { Dispatch } from "redux"

import { Api } from "api"
import { AppState } from "store/AppState"
import { PaginationRequest } from "types/ApiResponse"
import { showErrorNotification } from "utils/errors"

import {
  fetchExpiredAccountsError,
  fetchExpiredAccountsRequest,
  fetchExpiredAccountsSuccess,
  fetchTestAccountsError,
  fetchTestAccountsRequest,
  fetchTestAccountsSuccess,
  removeAccountError,
  removeAccountRequest,
  removeAccountSuccess
} from "./actions"
import {
  selectIsExpiredFetching,
  selectIsTestFetching,
  selectIsAccountDeleting
} from "./selectors"

export const fetchExpiredAccounts =
  (pagination: PaginationRequest, sorting: SortDirection, search?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const isExpiredFetching = selectIsExpiredFetching(getState())
    try {
      if (!isExpiredFetching) {
        dispatch(fetchExpiredAccountsRequest())
        const res = await Api.getExpiredAccounts(pagination, sorting, search)

        dispatch(fetchExpiredAccountsSuccess(res.result, res.pagination))
      }
    } catch (err) {
      showErrorNotification(err, "Wystąpił błąd podczas pobierania")
      dispatch(fetchExpiredAccountsError(err))
    }
  }

export const fetchTestAccounts =
  (pagination: PaginationRequest, sorting: SortDirection, search?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const isTestFetching = selectIsTestFetching(getState())

    try {
      if (!isTestFetching) {
        dispatch(fetchTestAccountsRequest())
        const res = await Api.getTestAccounts(pagination, sorting, search)

        dispatch(fetchTestAccountsSuccess(res.result, res.pagination))
      }
    } catch (err) {
      showErrorNotification(err, "Wystąpił błąd podczas pobierania")
      dispatch(fetchTestAccountsError(err))
    }
  }

export const removeAccount =
  (borgId: number) => async (dispatch: Dispatch, getState: () => AppState) => {
    const isDeleting = selectIsAccountDeleting(getState())

    try {
      if (!isDeleting) {
        dispatch(removeAccountRequest())
        await Api.removeAccount(borgId)
        dispatch(removeAccountSuccess(borgId))
      }
    } catch (err) {
      showErrorNotification(err, "Wystąpił błąd podczas pobierania")
      dispatch(removeAccountError(err))
    }
  }