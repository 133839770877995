import * as React from "react"
import { SingleValue } from "react-select"

import SingleSelect, {
  SingleSelectOption
} from "components/Inputs/Select/SingleSelect"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { Text } from "components/Text"
import { Input } from "components/ui/input"
import {
  AutoNewsletterDeliveryType,
  options
} from "types/AutoNewsletterDelivery"
import { convertTimeToSeconds } from "utils/date"
import usePrefix from "utils/usePrefix"

type BasicConfigProps = {
  cycle: AutoNewsletterDeliveryType["cycle"]
  time: AutoNewsletterDeliveryType["time"]
  onChange: FieldValueUpdate
}
export const BasicConfig: React.FC<BasicConfigProps> = ({
  cycle,
  time,
  onChange
}) => {
  const t = usePrefix("AutoDelivery")

  return (
    <div className="flex">
      <div className="mr-2 flex w-36 flex-col gap-1">
        <Text>{t("deliveryCycle")}</Text>
        <div className="flex">
          <SingleSelect
            name="cycle"
            isSearchable={false}
            value={
              options.cycles
                .map((cycle) => ({ value: cycle, label: t(`${cycle}`) }))
                .find((option) => option.value === cycle) || undefined
            }
            onChange={(value) =>
              onChange(
                "cycle",
                (value as SingleValue<SingleSelectOption>)?.value
              )
            }
            options={options.cycles.map((cycle) => ({
              value: cycle,
              label: t(`${cycle}`)
            }))}
          />
        </div>
      </div>
      <div className="flex w-24 flex-col gap-1">
        <Text> {t("deliveryHour")}</Text>
        <div>
          <Input
            type="time"
            className="w-[118px]"
            value={time}
            onChange={(event) => {
              onChange("time", convertTimeToSeconds(event.target.value))
            }}
          />
        </div>
      </div>
    </div>
  )
}