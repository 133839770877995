import React from "react"
import { useDispatch } from "react-redux"

import { Button } from "components/Button"
import { Header } from "components/Text"
import { markAllAsRead } from "store/notifications"
import usePrefix from "utils/usePrefix"

const NotificationHeader = () => {
  const t = usePrefix("Notification")
  const dispatch = useDispatch()

  const onMarkAllAsRead = () => dispatch(markAllAsRead())

  return (
    <div className="flex items-center justify-between px-5">
      <Header className="mb-0">{t("popover_notification_header")}</Header>
      <Button variant="ghost" size="sm" onClick={onMarkAllAsRead}>
        {t("popover_mark_as_read_btn")}
      </Button>
    </div>
  )
}

export default NotificationHeader