import { useCallback, useEffect, useState } from "react"
import * as Yup from "yup"

import { Api } from "api"
import { FormValues } from "pages/CreatorConfigurationPages/CreatorAddContact/Steps/types"
import { SmtpSettings } from "types/AccountingOffice"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"
import { Shape } from "utils/validation/utils"

export type PasswordPromptFormValues = Pick<FormValues, "smtpPassword"> &
  Pick<SmtpSettings, "fromAddress">

export const useInitialValues = (): PasswordPromptFormValues => {
  const [smtpSettings, setSmtpSettings] = useState<SmtpSettings>()

  const fetchSmtpSettings = useCallback(async () => {
    try {
      const res = await Api.fetchAccouningOffice()
      setSmtpSettings(res.result.smtpSettings)
    } catch (err) {
      showErrorNotification(err, "Nie udało się pobrać ustawień SMTP")
    }
  }, [])

  useEffect(() => {
    fetchSmtpSettings()
  }, [fetchSmtpSettings])

  return {
    smtpPassword: "",
    fromAddress: smtpSettings?.fromAddress ?? ""
  }
}

export const useValidation = () => {
  const tValidation = usePrefix("Validation")

  return Yup.object<Shape<PasswordPromptFormValues>>({
    smtpPassword: Yup.string().required(tValidation("required"))
  })
}