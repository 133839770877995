import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { Toaster } from "components/ui/toaster"
import { TooltipProvider } from "components/ui/tooltip"
import configureStore from "store/configureStore"
import initTagManager from "utils/tagManager"
import "./globals.css"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./translations"

initTagManager()

export const store = configureStore()

window.onunload = function () {
  window.scrollTo(0, 0)
}

const rootElement = document.getElementById("root") as HTMLElement
if (!rootElement) {
  throw new Error("Root element with ID 'root' not found.")
}

const root = createRoot(rootElement)
root.render(
  <Provider store={store}>
    <TooltipProvider>
      <App />
      <Toaster />
    </TooltipProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if ((module as any).hot) {
  // TODO - Corresponding file is not included in tsconfig.json
  // Accept hot update
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept()
}