import React from "react"
import { ReactourStep } from "reactour"

import { Paragraph } from "components/Text"
import { TransFunc } from "utils/usePrefix"

import { SimpleStep } from "../components/SimpleStep"

export const sendTestNewsPageSteps = (t: TransFunc): ReactourStep[] => [
  {
    selector: "[data-tour='send-test-news-step-1']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_1_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_1_line_2")}</Paragraph>
            </>
          }
          onNextStep={() => goTo(1)}
        />
      )
    }
  },
  {
    selector: "[data-tour='send-test-news-step-2']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_2_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_2_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(0)}
          onNextStep={() => goTo(2)}
        />
      )
    }
  },
  {
    selector: "[data-tour='send-test-news-step-3']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_3_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_3_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(1)}
          onNextStep={() => goTo(3)}
        />
      )
    }
  },
  {
    selector: "[data-tour='send-test-news-step-4']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_4_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_4_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(2)}
          onNextStep={() => goTo(4)}
        />
      )
    }
  },
  {
    selector: "[data-tour='send-test-news-step-5']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_5_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_5_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(3)}
          onNextStep={() => goTo(5)}
        />
      )
    }
  },
  {
    selector: "[data-tour='send-test-news-step-6']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("send_news_step_6_line_1")}</Paragraph>
              <Paragraph>{t("send_news_step_6_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(4)}
        />
      )
    }
  }
]