import React from "react"

import { Button } from "components/Button"
import { Text } from "components/Text"
import { Checkbox } from "components/ui/checkbox"
import { Label } from "components/ui/label"
import { TooltipComponent } from "components/ui/tooltip"
import { formatDate } from "utils/date"
import usePrefix from "utils/usePrefix"

import { ClientReceiverType, SelectClientProps } from "./ClientListModal"

interface ClientListProps {
  clients: ClientReceiverType[]
}

export const ClientList: React.FC<ClientListProps & SelectClientProps> = ({
  clients,
  isClientRemovable,
  onClientRemove,
  onSelectClient,
  selectedClients,
  isResendModal,
  ...rest
}) => {
  return (
    <div className="my-2 h-full min-h-96 [&>div]:border-t [&>div]:border-border">
      {onSelectClient && isResendModal && !!clients.length && (
        <SelectAllRow {...rest} />
      )}
      {clients.map((client) => (
        <ClientRow
          key={client.id}
          onSelectClient={onSelectClient}
          selectedClients={selectedClients}
          isResendModal={isResendModal}
          isClientRemovable={isClientRemovable}
          onClientRemove={onClientRemove}
          {...client}
        />
      ))}
    </div>
  )
}

const ClientRow = ({
  companyName,
  fullName,
  email,
  phoneNumber,
  id,
  onSelectClient,
  selectedClients,
  resendingDateUtc,
  isResendModal,
  isClientRemovable,
  onClientRemove
}: ClientReceiverType &
  Pick<
    SelectClientProps,
    | "onSelectClient"
    | "selectedClients"
    | "isResendModal"
    | "isClientRemovable"
    | "onClientRemove"
  >) => {
  const t = usePrefix("Confirmation")

  return (
    <div className="px-4 py-6 text-secondary-foreground hover:shadow xl:px-20">
      <div className="flex items-center">
        {!!isResendModal && !!onSelectClient && id && (
          <div className="mr-4">
            <Checkbox
              key={id}
              checked={!!id && selectedClients?.includes(id)}
              onCheckedChange={() => onSelectClient(id)}
            />
          </div>
        )}
        <div className="flex w-full items-center justify-between gap-2">
          <div className="flex-1 overflow-hidden">
            <h4 className="m-0 truncate">{companyName}</h4>
            {!!onSelectClient && !!resendingDateUtc && (
              <h5 className="stroke-secondary-foreground">
                {t("resend_date")} {formatDate(resendingDateUtc)}
              </h5>
            )}
          </div>
          <div className="flex-1 overflow-hidden">
            <Text $type="large">{fullName}</Text>
          </div>
          <div className="contact-data flex-1 overflow-hidden">{email}</div>
          <div className="contact-data flex-1 overflow-hidden">
            {!!phoneNumber && phoneNumber}
          </div>
          <div className="flex w-[30px] justify-end">
            {isClientRemovable && id && (
              <TooltipComponent
                triggerContent={
                  <Button
                    variant="link"
                    iconType="delete"
                    onClick={() => onClientRemove?.(id)}
                    style={{ justifyContent: "flex-end" }}
                  />
                }
                tooltipContent={t("delete_tooltip")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const SelectAllRow = ({
  handleSelectAll,
  isSelectedAll
}: Pick<SelectClientProps, "handleSelectAll" | "isSelectedAll">) => {
  const t = usePrefix("Confirmation")
  return (
    <div className="px-4 py-6 text-secondary-foreground hover:shadow xl:px-20">
      <div className="flex items-center gap-2">
        {!!handleSelectAll && (
          <>
            <Checkbox
              id="select_all"
              checked={isSelectedAll}
              onCheckedChange={() => handleSelectAll()}
            />
            <Label htmlFor="select_all" className="cursor-pointer">
              {t("select_all")}
            </Label>
          </>
        )}
      </div>
    </div>
  )
}