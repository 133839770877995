import React, { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { MultiValue, MultiValueGenericProps } from "react-select"

import FormFieldWrapper from "components/Form/FormFieldWrapper"
import MultipleSelect, {
  MultipleSelectOption
} from "components/Inputs/Select/MultipleSelect"
import { Tag } from "components/Tag/Tag"
import { AppState } from "store/AppState"
import { selectPKDList } from "store/pkd"
import usePrefix from "utils/usePrefix"

interface PkdSelectProps {
  pkds: string[]
  logicalAlternativePkds: string[]
  formType: "client" | "news"
  isDisabled: boolean
  setValues: (pkds: string[], logicalAlternativePkds: string[]) => void
}

export const PkdSelect = ({
  pkds,
  logicalAlternativePkds,
  formType,
  isDisabled,
  setValues
}: PkdSelectProps) => {
  const t = usePrefix("Tags")
  const allPKDs = useSelector((s: AppState) => selectPKDList(s, formType))

  const value = useMemo(() => {
    return allPKDs.filter(({ value }) =>
      [...pkds, ...logicalAlternativePkds].includes(value)
    )
  }, [allPKDs, logicalAlternativePkds, pkds])

  const options = useMemo(() => {
    return allPKDs.map(({ value, label, level }) => ({
      value,
      label: `${value} - ${label}`,
      level
    }))
  }, [allPKDs])

  const handleSelectChange = useCallback(
    (newValue: MultiValue<MultipleSelectOption>) => {
      const selectedPkds: string[] = []
      const selectedAlternativeTags: string[] = []

      newValue.forEach(({ value }) => {
        if (pkds.includes(value as string)) {
          selectedPkds.push(value as string)
        } else if (logicalAlternativePkds.includes(value as string)) {
          selectedAlternativeTags.push(value as string)
        } else {
          selectedPkds.push(value as string)
        }
      })

      setValues(selectedPkds, selectedAlternativeTags)
    },
    [logicalAlternativePkds, pkds, setValues]
  )

  const handleChangeOptional = useCallback(
    (pkd: string) => (isChecked: boolean, isIndeterminate: boolean) => {
      if (isIndeterminate) {
        setValues(
          pkds.filter((selected) => selected !== pkd),
          [...logicalAlternativePkds, pkd]
        )
      } else if (formType === "news") {
        setValues(
          [...pkds, pkd],
          logicalAlternativePkds.filter((selected) => selected !== pkd)
        )
      }
    },
    [formType, logicalAlternativePkds, pkds, setValues]
  )

  const MultiValueContainer = (
    props: MultiValueGenericProps<MultipleSelectOption>
  ) => {
    const { value, label } = props.data

    const isChecked = pkds.some((tag) => tag === value)
    const isIndeterminate =
      formType === "news" && logicalAlternativePkds.some((tag) => tag === value)

    return (
      <div className="m-[2px]">
        <Tag
          checked={isChecked}
          isIndeterminate={isIndeterminate}
          formType={formType}
          tooltip={typeof label === "string" ? label : ""}
          onChange={handleChangeOptional(value)}
          onClose={() => {
            if (isIndeterminate) {
              setValues(
                pkds,
                logicalAlternativePkds.filter((v) => v !== value)
              )
            } else {
              setValues(
                pkds.filter((v) => v !== value),
                logicalAlternativePkds
              )
            }
          }}
        >
          {value}
        </Tag>
      </div>
    )
  }

  return (
    <FormFieldWrapper name="pkds" label={t("pkd_label")}>
      <MultipleSelect
        name="pkds"
        virtualList
        placeholder={t("select_placeholder")}
        hideSelectedOptions={false}
        additionalPadding={formType === "news"}
        isClearable
        components={{
          MultiValueContainer
        }}
        value={value}
        onSelectChange={handleSelectChange}
        options={options}
        isDisabled={isDisabled}
      />
    </FormFieldWrapper>
  )
}