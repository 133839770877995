import * as LocalStorage from "store/storage/localStorage"
import { Session } from "types/Session"

export function hitTagManager() {
  const session: Session | undefined = LocalStorage.loadLocalState(
    LocalStorage.SESSION
  )

  if (window.dataLayer && session?.borgUserId) {
    const elementExists = window.dataLayer.some(
      (item: {
        event: string
        userId: number
        clientId: string
        originalReferrer: string
      }) => item.event === "info"
    )

    if (!elementExists) {
      window.dataLayer.push({
        event: "info",
        userId: session.borgUserId,
        clientId: session.borgClientId,
        originalReferrer: document.referrer
      })
    }
  }
}