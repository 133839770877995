import React, { ReactNode } from "react"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "components/ui/accordion"
import usePrefix from "utils/usePrefix"

export type AccordionProps = {
  items: {
    trigger: ReactNode
    name: string
    accordionContent?: ReactNode
    onTriggerClick?: () => void
  }[]
  disabled?: boolean
  header?: ReactNode
}

export const GenericAccordion = ({ items, disabled }: AccordionProps) => {
  const tGeneral = usePrefix("General")

  return (
    <Accordion type="multiple">
      {items.length ? (
        items.map(({ trigger, accordionContent, name, onTriggerClick }) => (
          <AccordionItem key={String(name)} value={String(name)}>
            <AccordionTrigger
              className="text-sm font-bold hover:text-primary hover:no-underline disabled:cursor-not-allowed disabled:hover:no-underline"
              disabled={disabled}
              onClick={onTriggerClick}
            >
              {trigger}
            </AccordionTrigger>
            <AccordionContent>{accordionContent}</AccordionContent>
          </AccordionItem>
        ))
      ) : (
        <AccordionItem key="noData" value="noData">
          <AccordionTrigger
            className="text-sm font-bold disabled:cursor-not-allowed disabled:hover:no-underline"
            disabled
          >
            {tGeneral("noData")}
          </AccordionTrigger>
        </AccordionItem>
      )}
    </Accordion>
  )
}