import { serialize, defaults, send } from "api/config"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import {
  Client,
  ClientDataFile,
  ClientDeleteListRequest,
  ClientFilters,
  ClientGUS,
  GroupedClientsByTag,
  TagFilter,
  UnsubscribedThankYouResponse
} from "types/Client"

import { request } from "../config"

const PATH_NAME = "Client"

export const fetchClients = async (
  pagination: PaginationRequest,
  filters: ClientFilters
): Promise<ResultModel<Client[]>> =>
  request.patch(`${PATH_NAME}${serialize(pagination)}`, filters)

export const fetchClientById = async (
  id: string
): Promise<ResultModel<Client>> => request.get(`${PATH_NAME}/${id}`)

export const addClient = async (
  client: Client,
  isForce?: boolean,
  isForceEmail?: boolean
): Promise<ResultModel<Client>> =>
  request.post(
    `${PATH_NAME}${serialize({
      ignoreTagsExclusionValidation: isForce,
      ignoreEmailExistion: isForceEmail
    })}`,
    client
  )

export const updateClient = async (
  client: Client,
  isForce?: boolean,
  isForceEmail?: boolean
): Promise<ResultModel<Client>> => {
  return request.put(
    `${PATH_NAME}/${client.id}${serialize({
      ignoreTagsExclusionValidation: isForce,
      ignoreEmailExistion: isForceEmail
    })}`,
    client
  )
}

export const deleteClient = async (id: string): Promise<string> =>
  request.delete(`${PATH_NAME}/${id}`)

export const deleteClientsList = async (
  params: ClientDeleteListRequest
): Promise<string> =>
  request.delete(
    `${PATH_NAME}${serialize({
      removeAll: params.removeAll
    })}`,
    { receiverIds: params.receiverIds }
  )

export const activateClient = async (
  id: string
): Promise<ResultModel<Client>> => request.patch(`${PATH_NAME}/${id}/Enable`)

export const deactivateClient = async (
  id: string
): Promise<ResultModel<Client>> => request.patch(`${PATH_NAME}/${id}/Disable`)

export const unsubscribeClient = async (
  token: string
): Promise<ResultModel<UnsubscribedThankYouResponse>> =>
  request.patch(`${PATH_NAME}/Unsubscribe/${token}`)

export const confirmMail = async (
  token: string
): Promise<ResultModel<UnsubscribedThankYouResponse>> =>
  request.patch(`${PATH_NAME}/Confirm/${token}`)

export const getClientFromGUSbyNIP = async (
  nip: string
): Promise<ResultModel<ClientGUS[]>> =>
  request.get(`${PATH_NAME}/DownloadData/${nip}`)

export const isNipOccupied = async (
  nip: string,
  clientId?: string
): Promise<ResultModel<boolean>> =>
  request.get(`${PATH_NAME}/IsNipOccupied${serialize({ nip, clientId })}`)

export const uploadClients = async (
  formData: FormData,
  signal?: any
): Promise<ResultModel<ClientDataFile>> => {
  const params = defaults.post()
  delete params.headers["Content-Type"]

  return await send(defaults.endpoint(`${PATH_NAME}/UploadClientDataFile`), {
    method: "POST",
    headers: params.headers,
    body: formData,
    signal
  })
}

export const searchClient = async (
  phrase: string,
  pagination: PaginationRequest,
  filters: ClientFilters
): Promise<ResultModel<Client[]>> =>
  request.patch(
    `${PATH_NAME}/Search${serialize({ phrase, ...pagination })}`,
    filters
  )

export const getGroupedClients = async (
  phrase?: string
): Promise<ResultModel<GroupedClientsByTag>> =>
  request.get(
    `${PATH_NAME}/ClientActivity/CountClients${serialize({ phrase })}`
  )

export const getClientsByTag = async (
  tagFilter: TagFilter,
  phrase?: string
): Promise<ResultModel<Client[]>> =>
  request.get(
    `${PATH_NAME}/ClientActivity/RetrieveClients${serialize({
      ...tagFilter,
      phrase
    })}`
  )

export const getClientsExcelFile = async (): Promise<any> => {
  return await request.get(`${PATH_NAME}/ClientExcelFile`)
}